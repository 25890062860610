import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const ProfileCard = ({ data }) => (
  <div className="block">
    <div className="flex items-center justify-center">
      <Link
        to="/"
        className="flex space-x-4 items-center justify-center"
        title="Logo"
      >
        <img
          className="relative inline-flex w-full"
          src="/img/purple_title.png"
          alt="no worries lifestyle title image"
        />
      </Link>
    </div>
    <div className="flex mx-auto text-2xl mt-10 w-3/4 text-green-dense hover:text-green-900 font-black py-2 items-center">
      current projects
    </div>
    <div className="flex py-2 mt-10 mb-4 mx-auto w-3/4 px-5 bg-white shadow-2xl rounded-3xl hover:animate-bounce">
      <Link
        to="https://apps.apple.com/us/app/30-second-sketch/id1534806022"
        className="sm:flex space-x-4 items-center justify-left"
        title="Logo"
      >
        <img
          className="items-left w-1/2"
          src="/img/sketch.png"
          alt="sketch title image"
        />
        <div className="block w-3/4 text-lg text-green-dense hover:text-green-900 font-normal py-2 items-center">
          <p className="py-2">
            What can you accomplish in 30 seconds? We're willing to bet it's
            something incredible. Train your brain and test your creativity by
            sharing with others one of the most primitive instincts we have.
            Drawing.
          </p>
          <p className="py-2">
            Join now and see what everyone is hyped about. Gone are the days of
            absorbing and time-consuming social media experiences. 30 Second
            Sketch reinvents social media by focusing on visual content creation
            and expression of art through drawing. Try now! It's free!
          </p>
        </div>
      </Link>
    </div>
    <div className="flex py-4 mt-10 mx-auto w-3/4 px-5 bg-white shadow-2xl rounded-3xl">
      <Link
        to="https://twitter.com/MrStockBot/"
        className="md:flex space-x-4 items-center justify-center"
        title="Logo"
      >
        <div className="flex w-1/2">
          <blockquote class="twitter-tweet">
            <p lang="en" dir="ltr">
              hi. i&#39;m stock bot. i can do a few things for you. just mention{" "}
              <a href="https://twitter.com/MrStockBot?ref_src=twsrc%5Etfw">
                @MrStockBot
              </a>{" "}
              and ask me things like:
              <br /> - what&#39;s the price of{" "}
              <a href="https://twitter.com/search?q=%24BTC&amp;src=ctag&amp;ref_src=twsrc%5Etfw">
                $BTC
              </a>
              ?<br /> - can you show me the fundamentals of{" "}
              <a href="https://twitter.com/search?q=%24TSLA&amp;src=ctag&amp;ref_src=twsrc%5Etfw">
                $TSLA
              </a>
              ?<br /> - can you tell me more about{" "}
              <a href="https://twitter.com/search?q=%24ZM&amp;src=ctag&amp;ref_src=twsrc%5Etfw">
                $ZM
              </a>
              ?<br />
              i&#39;m always learning new tricks, so stay tuned 🤖
            </p>
            &mdash; Stock Bot (@MrStockBot){" "}
            <a href="https://twitter.com/MrStockBot/status/1344356664582021121?ref_src=twsrc%5Etfw">
              December 30, 2020
            </a>
          </blockquote>
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </div>
        <div className="flex w-3/4 text-lg text-green-dense hover:text-green-900 font-normal py-2 items-center">
          Mr. Stock Bot is the friendliest bot on Twitter. He likes to respond
          to natural language with financial information. Join thousands of
          other users in questioning Mr. Stock Bot's vast investment knowledge.
        </div>
      </Link>
    </div>
    <div className="flex py-2 mt-10 mx-auto w-3/4 px-5 bg-white shadow-2xl rounded-3xl">
      <Link
        to="https://twitter.com/pulst_sentiment/"
        className="sm:flex space-x-4 items-center justify-center"
        title="Logo"
      >
        <div className="flex w-1/2">
          <blockquote class="twitter-tweet" data-theme="light">
            <p lang="en" dir="ltr">
              here&#39;s our daily assessment of twitter market sentiment{" "}
              <a href="https://twitter.com/hashtag/fintwit?src=hash&amp;ref_src=twsrc%5Etfw">
                #fintwit
              </a>{" "}
              <a href="https://t.co/CAnLaW8uAc">pic.twitter.com/CAnLaW8uAc</a>
            </p>
            &mdash; pulst (@pulst_sentiment){" "}
            <a href="https://twitter.com/pulst_sentiment/status/1395915783973265408?ref_src=twsrc%5Etfw">
              May 22, 2021
            </a>
          </blockquote>{" "}
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </div>
        <div className="flex w-3/4 text-lg text-green-dense hover:text-green-900 font-normal py-2 items-center">
          Pulst is a bot that scours Twitter for tweets containing financial
          information. As the tweets are collected, the sentiment information is
          reported to its audience on a daily basis. The current database
          currently sits at millions of tweets and grows each day.
        </div>
      </Link>
    </div>
    <div className="flex py-2 mt-10 mb-4 mx-auto w-3/4 px-5 bg-white shadow-2xl rounded-3xl">
      <Link
        to="https://www.petrolytics.io"
        className="sm:flex space-x-4 items-center justify-left"
        title="Logo"
      >
        <img
          className="items-left w-1/2"
          src="/img/petrolytics.png"
          alt="petrolytics title image"
        />
        <div className="flex w-3/4 text-lg text-green-dense hover:text-green-900 font-normal py-2 items-center">
          petrolytics.io is a new breed of energy news. Our purpose - to scour
          data sets across the public domain and to provide a simple, clean
          interface for data exploration and analysis. We believe that data is
          the story, so we're committed to compiling the richest data sets with
          full transparency and access. From unconventional oil and gas plays,
          to offshore wind farms, we're here to navigate the changing energy
          landscape.
        </div>
      </Link>
    </div>
    <div className="flex py-2 mt-10 mb-4 mx-auto w-3/4 px-5 bg-white shadow-2xl rounded-3xl">
      <Link
        to="https://apps.apple.com/us/app/peakly/id1540835009"
        className="sm:flex space-x-4 items-center justify-left"
        title="Logo"
      >
        <img
          className="items-left w-1/2"
          src="/img/peakly.png"
          alt="peakly title image"
        />
        <div className="block w-3/4 text-lg text-green-dense hover:text-green-900 font-normal py-2 items-center">
          <p className="py-2">
            The privacy-conscious, simple-to-use, and one-of-a-kind daily lung
            journal has finally arrived. peakly is your asthma, COPD, and
            allergy symptoms diary. peakly is the premier diary dedicated to
            tracking your asthma, COPD and allergy symptoms, as well as, your
            general lung health. Log information including:
          </p>
          <ul className="py-2 px-4 list-disc">
            <li>Peak flow readings</li>
            <li>Rescue inhaler frequency</li>
            <li>Allergy medicine usage</li>
            <li>Exercise</li>
            <li>General well-being</li>
            <li>And much more</li>
          </ul>
          <p className="py-2">
            peakly values your privacy and does not transmit, share, or store
            data outside of your personal device. This means that if the app is
            removed, all of your data is also removed from the device (unless
            you've exported your data).
          </p>
          <p className="py-2">
            Disclaimer: peakly does not provide medical advice. It is intended
            for informational purposes only. It is not a substitute for
            professional medical advice, diagnosis or treatment. Never ignore
            professional medical advice in seeking treatment because of peakly.
            If you think you may have a medical emergency, immediately call your
            doctor or dial 911.
          </p>
        </div>
      </Link>
    </div>
    <div className="flex py-2 mt-10 mb-4 mx-auto w-3/4 px-5 bg-white shadow-2xl rounded-3xl">
      <Link
        to="https://apps.apple.com/us/app/cosmic-lasso/id1517663034"
        className="sm:flex space-x-4 items-center justify-left"
        title="Logo"
      >
        <img
          className="items-left w-1/2"
          src="/img/cosmiclasso.png"
          alt="cosmiclasso title image"
        />
        <div className="flex w-3/4 text-lg text-green-dense hover:text-green-900 font-normal py-2 items-center">
          Cosmic Lasso is a new, simple, and relaxing puzzler of galactic
          proportions! Train your mind and focus with 50 unique levels + an
          endless play mode! Take the time to quickly immerse yourself in this
          spacey, rhythmic, and meditative game. Use your finger to lasso the
          like-colored orbitals as they zip around the screen. But be careful!
          Don't leave any stranded behind!
        </div>
      </Link>
    </div>
    <div className="flex py-2 mt-10 mb-4 mx-auto w-3/4 px-5 bg-white shadow-2xl rounded-3xl">
      <Link
        to="https://apps.apple.com/us/app/squirmy-worm/id1518843026"
        className="sm:flex space-x-4 items-center justify-left"
        title="Logo"
      >
        <img
          className="items-left w-1/2"
          src="/img/squirmyworm.png"
          alt="squirmy worm title image"
        />
        <div className="block w-3/4 text-lg text-green-dense hover:text-green-900 font-normal py-2 items-center">
          <p className="py-2">
            Check out the game that has the internet squirming with excitement.
            Squirmy Worm is a worldwide sensation of itty-bitty proportions!
          </p>
          <ul className="py-2 px-4 list-disc">
            <li className="py-2">
              "The most frustrating, yet addictive game we've ever played" -
              Anonymous indie game developer
              <li>
                "I can't stop playing this dumb game. It's driving me mad!!" -
                Game reviewer
              </li>
            </li>
            <li className="py-2">"Is this a joke game?" - Developer's wife</li>
          </ul>
          <p className="py-2">
            Squirmy Worm is simply beautiful. The game has taken the internet by
            storm in a squirmy fashion! See what the hype is all about and
            challenge your friends to beat your squirm squirm record!
          </p>
        </div>
      </Link>
    </div>
  </div>
);

ProfileCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      plan: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      description: PropTypes.string,
      items: PropTypes.array,
    })
  ),
};

export default ProfileCard;
